/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { Heading, SubHeading } from '../../atoms/typography/Typography';
import ConclusionPage from './ConclusionPage';
import HubSpotForm from '../../atoms/hubspot-form/HubSpotForm';

const SelfServiceConclusionPage = passThroughProps => (
  <ConclusionPage
    {...passThroughProps}
    PageHeading={() => (
      <Heading variant="small" center>
        Thank You for Interacting with the Demo
      </Heading>
    )}
    PageSubHeading={() => (
      <SubHeading variant="small" center>
        Please complete the form to have a member of our sales team connect with
        you.
      </SubHeading>
    )}
    PageIFrame={() => (
      <HubSpotForm hubSpotFormId="ffe1d6db-31f1-4b33-b118-4c83ccfb10a8" />
    )}
    PagePostIFrameContent={() => (
      <a href="https://www.dwolla.com/">Back to Dwolla.com &rarr;</a>
    )}
  />
);

export default SelfServiceConclusionPage;
