import { connect } from 'react-redux';
import BankVerifiedPage from './BankVerifiedPage';

const mapStateToProps = state => ({
  theme: state.general.theme,
  customerId: state.general.offlineMode
    ? null
    : state.createCustomer.createdCustomer.id,
  inviterFirstName: state.createCustomer.facilitatorCustomer.firstName,
  inviterLastName: state.createCustomer.facilitatorCustomer.lastName,
});

export default connect(mapStateToProps)(BankVerifiedPage);
