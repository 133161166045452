import React from 'react';
import PropTypes from 'prop-types';
import styles from './ChooseBankMethodScreen.module.scss';
import { themeOptions } from '../../../../utils/propTypeHelper';
import { Heading, SubHeading } from '../../../atoms/typography/Typography';
import Card from '../../../atoms/card/Card';

const ChooseBankMethodScreen = ({
  theme,
  offlineMode,
  handlePlaidClicked,
  handleMicroClicked,
}) => {
  return (
    <>
      <div className={styles.container}>
        <Heading variant="theme" theme={theme} center>
          Attach a Bank
        </Heading>
        <Card
          theme={theme}
          buttonText="Use Deposit Verification"
          handleButtonClick={handleMicroClicked}
        >
          <SubHeading variant="phone">Micro-Deposit Verification</SubHeading>
          <p>Requires your account and routing number.</p>
          <p>
            Takes 1-3 business days for two small deposits to appear in your
            account.
          </p>
        </Card>
        {!offlineMode && (
          <Card
            theme={theme}
            buttonText="Use Plaid"
            handleButtonClick={handlePlaidClicked}
          >
            <SubHeading variant="phone">Plaid Integration</SubHeading>
            <p>Requires your bank username and password.</p>
          </Card>
        )}
      </div>
    </>
  );
};

ChooseBankMethodScreen.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  offlineMode: PropTypes.bool,
  handlePlaidClicked: PropTypes.func.isRequired,
  handleMicroClicked: PropTypes.func.isRequired,
};

ChooseBankMethodScreen.defaultProps = {
  offlineMode: false,
};

export default ChooseBankMethodScreen;
