import React from 'react';
import classNames from 'classnames';
import styles from './Labels.module.scss';
import config from '../../../config';

const Labels = () => (
  <div className={styles.labelsComponent}>
    <div className={classNames(styles.label, styles.back)}>
      <div className={styles.text}>
        <div className={styles.term}>Payments Ecosystem</div>
        <div
          className={classNames(styles.description, styles[config.versionName])}
        >
          Dwolla’s simplified API gives you access to the complex payment
          networks with cost-efficient money movement options.
        </div>
      </div>
      <div className={styles.shapes}>
        <div className={styles.dot} />
        <div className={styles.dashedLine} />
      </div>
    </div>
    <div className={classNames(styles.label, styles.middle)}>
      <div className={classNames(styles.text, styles.wide)}>
        <div className={styles.term}>Dwolla API Layer</div>
        <div
          className={classNames(styles.description, styles[config.versionName])}
        >
          Dwolla acts as an onramp for customer-facing applications to connect
          to modern payments infrastructure.
        </div>
      </div>
      <div className={styles.shapes}>
        <div className={styles.dot} />
        <div className={styles.shortDashedLine} />
        <div className={styles.slantedDashedLine} />
      </div>
    </div>
    <div className={classNames(styles.label, styles.front)}>
      <div className={styles.shapes}>
        <div className={styles.dashedLine} />
        <div className={styles.dot} />
      </div>
      <div className={styles.text}>
        <div className={styles.term}>Your Interface</div>
        <div
          className={classNames(styles.description, styles[config.versionName])}
        >
          Your app runs smoothly while Dwolla allows you or your end users to
          move money behind the scenes, creating a seamless user experience.
        </div>
      </div>
    </div>
  </div>
);

export default Labels;
