import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
} from 'react-bootstrap';
import PageNav from '../../molecules/page-nav/PageNav';
import Layers from '../../molecules/layers/Layers';
import styles from './SelectDemoTrackPage.module.scss';
import config from '../../../config';

const SelectDemoTrackPage = ({ demoTrack, setDemoTrack }) => (
  <div className={styles.demoSelectPage}>
    <Container className="container__center-content">
      <div className={styles.content}>
        <ButtonToolbar className={styles.buttons}>
          <ToggleButtonGroup
            type="radio"
            name="funds-flows"
            defaultValue={demoTrack}
            onChange={setDemoTrack}
          >
            <ToggleButton value="app" variant="pill-white">
              Build Your App on the Dwolla API
            </ToggleButton>
            <ToggleButton value="integration" variant="pill-white">
              Build an Integration with Dwolla
            </ToggleButton>
          </ToggleButtonGroup>
        </ButtonToolbar>

        <div className={styles.layers}>
          <Layers selectedDemoTrack={demoTrack} playTransitionAnimation />
        </div>
      </div>
    </Container>

    <PageNav
      prevLink={config.tourFlow.selectTrack.back}
      nextLink={config.tourFlow.selectTrack.next(demoTrack)}
      bg="gradient"
    />
  </div>
);

SelectDemoTrackPage.propTypes = {
  demoTrack: PropTypes.string.isRequired,
  setDemoTrack: PropTypes.func.isRequired,
};

export default SelectDemoTrackPage;
