import send from '../assets/images/funds-flows/Send.mp4';
import receive from '../assets/images/funds-flows/Receive.mp4';
import sendReceive from '../assets/images/funds-flows/Send-Receive.mp4';
import facilitate from '../assets/images/funds-flows/Facilitate.mp4';
import meToMe from '../assets/images/funds-flows/Me-to-Me.mp4';

import read from '../assets/images/integration-scopes/Read.mp4';
import write from '../assets/images/integration-scopes/Write.mp4';
import transact from '../assets/images/integration-scopes/Transact.mp4';

export const flowOptions = {
  send: {
    label: 'Send',
    video: send,
    description: 'Send funds to your users.',
  },
  receive: {
    label: 'Receive',
    video: receive,
    description: 'Receive funds from your users.',
  },
  sendReceive: {
    label: 'Send & Receive',
    video: sendReceive,
    description:
      'Allow users to send funds to you and receive funds from your users.',
  },
  facilitate: {
    label: 'Facilitate',
    video: facilitate,
    description:
      'Facilitate the transfer of funds between users through your application.',
  },
  meToMe: {
    label: 'Me to Me',
    video: meToMe,
    description: 'Allow users to send funds from one account to another.',
  },
};

export const scopeOptions = {
  read: { label: 'Read', video: read },
  write: { label: '+Write', video: write },
  transact: { label: '+Transact', video: transact },
};
