import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import PageNav from '../../molecules/page-nav/PageNav';
import { Heading } from '../../atoms/typography/Typography';
import PartnerCardGrid from '../../molecules/partner-card/PartnerCardGrid';
import partnerInfo from '../../../utils/staticContentHelper';
import config from '../../../config';

const PartnerCardsPage = ({ demoTrack, PageHeading, PageSubHeading }) => (
  <div>
    <Container className="container__center-content">
      <PageHeading />
      <PageSubHeading />
      <PartnerCardGrid partnerInfo={partnerInfo} />
    </Container>

    <PageNav
      prevLink={config.tourFlow.partners.back(demoTrack)}
      nextLink={config.tourFlow.partners.next}
      bg="gradient"
    />
  </div>
);

PartnerCardsPage.propTypes = {
  demoTrack: PropTypes.string.isRequired,
  PageHeading: PropTypes.func,
  PageSubHeading: PropTypes.func,
};

PartnerCardsPage.defaultProps = {
  PageHeading: () => <Heading>Dwolla Partners</Heading>,
  PageSubHeading: () => <></>,
};

export default PartnerCardsPage;
