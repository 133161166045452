import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './ThemePickerButton.module.scss';
import { themeOptions } from '../../../utils/propTypeHelper';

import insuranceLogo from '../../../assets/images/phone-interface/icon-insurance-app.svg';
import marketplaceLogo from '../../../assets/images/phone-interface/icon-marketplace-app.svg';
import personalFinanceLogo from '../../../assets/images/phone-interface/icon-personal-finance-app.svg';
import rentalLogo from '../../../assets/images/phone-interface/icon-rental-app.svg';

const logos = {
  insurance: insuranceLogo,
  marketplace: marketplaceLogo,
  personalFinance: personalFinanceLogo,
  rental: rentalLogo,
};

const ThemePickerButton = ({ theme, label }) => {
  return (
    <div className={styles.themePickerButtonComponent}>
      <img src={logos[theme]} alt={`${label} Logo`} className={styles.logo} />

      <div className={styles.label}>{label}</div>

      <div className={classNames(styles.colors)}>
        <div className={styles.primary}>
          <div className={classNames(styles.color, styles[theme])} />
        </div>

        <div className={styles.secondary}>
          <div className={classNames(styles.color, styles[theme])} />
        </div>

        <div className={styles.tertiary}>
          <div className={classNames(styles.color, styles[theme])} />
        </div>

        <div className={styles.quaternary}>
          <div className={classNames(styles.color, styles[theme])} />
        </div>
      </div>
    </div>
  );
};

ThemePickerButton.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  label: PropTypes.string.isRequired,
};

export default ThemePickerButton;
