import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import PageNav from '../../molecules/page-nav/PageNav';
import VideoLoopSelector from '../../molecules/video-loop-selector/VideoLoopSelector';
import { Heading, SubHeading } from '../../atoms/typography/Typography';
import { scopeOptions } from '../../../utils/video-options';
import config from '../../../config';

const IntegrationScopesPage = () => {
  const [integrationScope, setIntegrationScope] = useState('read');

  return (
    <div className="text-center">
      <Container className="container__center-content">
        <Heading>Integrate with the Dwolla API</Heading>
        <SubHeading>Integration Scope</SubHeading>

        <VideoLoopSelector
          videoOptions={scopeOptions}
          selectedVideo={integrationScope}
          setSelectedVideo={setIntegrationScope}
        />
      </Container>

      <PageNav
        prevLink={config.tourFlow.integrationScopes.back}
        nextLink={config.tourFlow.integrationScopes.next}
        bg="gradient"
      />
    </div>
  );
};

export default IntegrationScopesPage;
