import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import PageNav from '../../molecules/page-nav/PageNav';
import { Heading, SubHeading } from '../../atoms/typography/Typography';
import StepOverview from '../../molecules/step-overview/StepOverview';
import BackgroundImage from '../../atoms/background-image/BackgroundImage';
import PhoneInterface from '../../molecules/phone-interface/PhoneInterface';
import styles from './StepOverviewPage.module.scss';
import config from '../../../config';

import startInsurance from '../../../assets/images/phone-interface/start-screen-insurance.png';
import startMarketplace from '../../../assets/images/phone-interface/start-screen-marketplace.png';
import startPersonalFinance from '../../../assets/images/phone-interface/start-screen-personal-finance.png';
import startRental from '../../../assets/images/phone-interface/start-screen-rental.png';

const startScreens = {
  insurance: startInsurance,
  marketplace: startMarketplace,
  personalFinance: startPersonalFinance,
  rental: startRental,
};

const steps = [
  {
    name: 'Create Users',
    description:
      "First, we'll create an end user, which is a Dwolla customer record that we can point to for our other API calls.",
  },
  {
    name: 'Attach Payment Accounts',
    description:
      "Then, we'll attach a bank account to that customer record using sample bank information.",
  },
  {
    name: 'Initiate Transfers',
    description:
      "Lastly, we'll point out a source and destination for the transaction to initiate a transfer.",
  },
];

const StepOverviewPage = ({ theme }) => (
  <div>
    <Container className="container__center-content">
      <div className={styles.content}>
        <div className={styles.stepOverviewWrap}>
          <Heading variant="small">Build Your App on the Dwolla API</Heading>
          <SubHeading variant="small">
            There are three main steps to initiating a payment with the Dwolla
            API. In this demo, we&apos;ll walk you through how to:
          </SubHeading>
          <StepOverview steps={steps} />
        </div>

        <div className={styles.phoneWrap}>
          <PhoneInterface position="right">
            <BackgroundImage
              src={startScreens[theme]}
              alt={`${theme} example interface`}
              className={styles.phoneImage}
            >
              {theme && (
                <Link
                  to={config.tourFlow.stepOverview.next}
                  className={styles.imageLink}
                />
              )}
            </BackgroundImage>
          </PhoneInterface>
        </div>
      </div>
    </Container>

    <PageNav
      prevLink={config.tourFlow.stepOverview.back}
      nextLink={config.tourFlow.stepOverview.next}
      bg="gradient"
    />
  </div>
);

StepOverviewPage.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default StepOverviewPage;
