import React from 'react';
import { Helmet } from 'react-helmet';
import config from './config';

export const CookieBarHelmet = () => (
  <Helmet>
    <script
      src="https://cdn.dwolla.com/cookie-consent/dwolla-consent.js"
      defer
    />
  </Helmet>
);

const DemoHelmet = () => (
  <Helmet>
    <title>Dwolla Demo</title>
    <meta name="description" content="Demo of Dwolla API Functionality" />
  </Helmet>
);

const CalcHelmet = () => (
  <Helmet>
    <title>How Much Do Payments Cost Your Company? | Dwolla</title>
    <meta
      name="description"
      content="Businesses can use the cost calculator to find out how much money can be saved by using the ACH Network for payments."
    />
    <script src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.6.1/iframeResizer.contentWindow.min.js" />
  </Helmet>
);

const ExportHelmet = () =>
  config.isCalculator ? <CalcHelmet /> : <DemoHelmet />;

export default ExportHelmet;
