/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { SubHeading } from '../../atoms/typography/Typography';
import PartnerCardsPage from './PartnerCardsPage';

const SelfServicePartnerCardsPage = passThroughProps => (
  <PartnerCardsPage
    {...passThroughProps}
    PageSubHeading={() => (
      <SubHeading variant="extraMargin" center>
        Dwolla allows you to move money in an efficient and cost-effective
        manner. We also have some great partners and integrations that help you
        connect to other tools and services to support your business.
      </SubHeading>
    )}
  />
);

export default SelfServicePartnerCardsPage;
