import { connect } from 'react-redux';
import { setVariation } from '../../../../../redux/modules/attach-bank';
import ChooseBankMethodPage from './ChooseBankMethodPage';

const mapStateToProps = state => ({
  theme: state.general.theme,
  bearerToken: state.general.bearerToken,
  customerUrl: state.createCustomer.createdCustomer._links.self.href,
  pending: state.attachBank.pending,
  offlineMode: state.general.offlineMode,
});

const mapDispatchToProps = {
  setVariation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseBankMethodPage);
