import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './PageNav.module.scss';
import { ReactComponent as WhiteLogo } from '../../../assets/images/global/dwolla-logo-white-all.svg';
import { ReactComponent as ColorLogo } from '../../../assets/images/global/dwolla-logo-full-color.svg';
import PreviousButton from './PreviousButton';
import NextButton from './NextButton';
import config from '../../../config';
import HelpButtonConnection from './HelpButtonConnection';

const DefaultLogo = ({ pageNavBackground }) =>
  pageNavBackground === 'white' ? <ColorLogo /> : <WhiteLogo />;

DefaultLogo.propTypes = {
  pageNavBackground: PropTypes.oneOf(['purple', 'gradient', 'white'])
    .isRequired,
};

const PageNav = ({
  prevLink,
  prevOnClick,
  PrevIcon,
  nextLink,
  nextText,
  nextPending,
  nextDisabled,
  nextOnClick,
  NextIcon,
  bg,
  Logo,
}) => (
  <div className={classNames(styles.pageNavComponent, styles[bg])}>
    <div className={styles.logo}>
      <Logo pageNavBackground={bg} />
    </div>
    <div className={styles.buttons}>
      {config.tourBubblesEnabled && <HelpButtonConnection />}
      <PreviousButton
        prevLink={prevLink}
        prevOnClick={prevOnClick}
        PrevIcon={PrevIcon}
        variant={bg === 'white' ? 'dwolla' : 'outline-light'}
      />
      <NextButton
        nextLink={nextLink}
        nextText={nextText}
        nextPending={nextPending}
        nextDisabled={nextDisabled}
        nextOnClick={nextOnClick}
        NextIcon={NextIcon}
        variant={bg === 'white' ? 'dwolla' : 'outline-light'}
      />
    </div>
  </div>
);

PageNav.propTypes = {
  prevLink: PropTypes.string,
  prevOnClick: PropTypes.func,
  PrevIcon: PropTypes.elementType,
  nextLink: PropTypes.string,
  nextText: PropTypes.string,
  nextPending: PropTypes.bool,
  nextDisabled: PropTypes.bool,
  nextOnClick: PropTypes.func,
  NextIcon: PropTypes.elementType,
  bg: PropTypes.oneOf(['purple', 'gradient', 'white']),
  Logo: PropTypes.elementType,
};

PageNav.defaultProps = {
  prevLink: '',
  prevOnClick: null,
  PrevIcon: undefined,
  nextLink: '',
  nextText: 'Next',
  nextPending: false,
  nextDisabled: false,
  nextOnClick: null,
  NextIcon: undefined,
  bg: 'purple',
  Logo: DefaultLogo,
};

export default PageNav;
