import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './PageNav.module.scss';
import Preloader from '../../atoms/preloader/Preloader';
import { ReactComponent as RightIcon } from '../../../assets/images/icons/icon-arrow-right.svg';

const NextButton = ({
  nextLink,
  nextText,
  nextPending,
  nextDisabled,
  nextOnClick,
  NextIcon,
  variant,
}) => {
  if (nextOnClick || nextDisabled) {
    return (
      <Button
        variant={variant}
        className={classNames('bubble-next-button', styles.button, {
          [styles.pending]: nextPending,
        })}
        onClick={nextOnClick}
        disabled={nextDisabled || nextPending}
      >
        <span className={styles.buttonText}>{nextText}</span>
        <span className={styles.buttonIcon} aria-label={nextText}>
          <NextIcon />
        </span>
        {nextPending && <Preloader context="button" />}
      </Button>
    );
  }
  if (nextLink) {
    return (
      <Link to={nextLink}>
        <Button
          variant={variant}
          className={classNames('bubble-next-button', styles.button)}
        >
          <span className={styles.buttonText}>{nextText}</span>
          <span className={styles.buttonIcon} aria-label={nextText}>
            <NextIcon />
          </span>
        </Button>
      </Link>
    );
  }
  return '';
};

NextButton.propTypes = {
  nextLink: PropTypes.string,
  nextText: PropTypes.string.isRequired,
  nextPending: PropTypes.bool,
  nextDisabled: PropTypes.bool,
  nextOnClick: PropTypes.func,
  NextIcon: PropTypes.elementType,
  variant: PropTypes.oneOf(['dwolla', 'outline-light']),
};

NextButton.defaultProps = {
  nextLink: null,
  nextPending: false,
  nextDisabled: false,
  nextOnClick: null,
  NextIcon: RightIcon,
  variant: 'outline-light',
};

export default NextButton;
