import { postJson } from '../../utils/api';
import { chance } from '../../utils/autofill';

export const SUBMIT_ATTACH_BANK = 'SUBMIT_ATTACH_BANK';
export const ATTACH_BANK_SUCCESS = 'ATTACH_BANK_SUCCESS';
export const ATTACH_BANK_FAILURE = 'ATTACH_BANK_FAILURE';
export const ATTACH_PLAID_BANK_FAILURE = 'ATTACH_PLAID_BANK_FAILURE';

export const SUBMIT_VERIFY_BANK = 'SUBMIT_VERIFY_BANK';
export const VERIFY_BANK_SUCCESS = 'VERIFY_BANK_SUCCESS';
export const VERIFY_BANK_FAILURE = 'VERIFY_BANK_FAILURE';

export const SUBMIT_PLAID_EXCHANGE = 'SUBMIT_PLAID_EXCHANGE';
export const PLAID_EXCHANGE_SUCCESS = 'PLAID_EXCHANGE_SUCCESS';
export const PLAID_EXCHANGE_FAILURE = 'PLAID_EXCHANGE_FAILURE';

export const INCREMENT_STEP = 'INCREMENT_STEP';
export const DECREMENT_STEP = 'DECREMENT_STEP';
export const SET_VARIATION = 'SET_VARIATION';

const initialState = {
  variation: 'default',
  subStep: 0,
  pending: false,
  plaidToken: '',
};

const generateFakePlaidProcessorToken = () => {
  return `processor-sandbox-${chance.guid()}`;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_VARIATION:
      return {
        ...state,
        variation: action.variation,
        subStep: state.subStep + 1,
      };
    case INCREMENT_STEP:
      return {
        ...state,
        subStep: state.subStep + 1,
      };
    case DECREMENT_STEP:
      return {
        ...state,
        subStep: state.subStep - 1,
      };
    case SUBMIT_ATTACH_BANK:
    case SUBMIT_VERIFY_BANK:
      return {
        ...state,
        pending: true,
      };
    case ATTACH_BANK_SUCCESS:
    case ATTACH_BANK_FAILURE:
    case ATTACH_PLAID_BANK_FAILURE:
      return {
        ...state,
        subStep: state.subStep + 1,
        pending: false,
      };
    case VERIFY_BANK_SUCCESS:
    case VERIFY_BANK_FAILURE:
      return {
        ...state,
        subStep: state.subStep + 1,
        pending: false,
      };
    case PLAID_EXCHANGE_SUCCESS:
      return {
        ...state,
        plaidToken: action.body.plaidToken,
      };
    case PLAID_EXCHANGE_FAILURE:
      return {
        ...state,
        plaidToken: generateFakePlaidProcessorToken(),
      };
    default:
      return state;
  }
}

export const submitAttachBank = ({
  customerUrl,
  bankAccountType,
  routingNumber,
  accountNumber,
  name,
}) => ({
  types: [SUBMIT_ATTACH_BANK, ATTACH_BANK_SUCCESS, ATTACH_BANK_FAILURE],
  payload: {
    name,
  },
  callAPI: () =>
    postJson(`/funding-sources?href=${encodeURIComponent(customerUrl)}`, {
      bankAccountType,
      routingNumber,
      accountNumber,
      name,
    }),
});

export const submitAttachPlaidBank = ({ customerUrl, plaidToken, name }) => ({
  types: [SUBMIT_ATTACH_BANK, ATTACH_BANK_SUCCESS, ATTACH_PLAID_BANK_FAILURE],
  payload: {
    name,
  },
  callAPI: () =>
    postJson(`/funding-sources?href=${encodeURIComponent(customerUrl)}`, {
      plaidToken,
      name,
    }),
});

export const submitVerifyBank = ({ fundingSourceUrl, amount1, amount2 }) => ({
  types: [SUBMIT_VERIFY_BANK, VERIFY_BANK_SUCCESS, VERIFY_BANK_FAILURE],
  payload: {
    fundingSourceUrl,
  },
  callAPI: () =>
    postJson(`/micro-deposits?href=${fundingSourceUrl}`, {
      amount1: {
        value: amount1,
        currency: 'USD',
      },
      amount2: {
        value: amount2,
        currency: 'USD',
      },
    }),
});

export const submitPlaidTokenExchange = ({
  customerUrl,
  publicToken,
  accountId,
  name,
}) => ({
  types: [
    SUBMIT_PLAID_EXCHANGE,
    PLAID_EXCHANGE_SUCCESS,
    PLAID_EXCHANGE_FAILURE,
  ],
  callAPI: () =>
    postJson('/plaid', {
      publicToken,
      accountId,
    }),
  dispatchOnSuccessDelay: 2000,
  dispatchOnSuccess: [
    response =>
      submitAttachPlaidBank({
        customerUrl,
        plaidToken: response.plaidToken,
        name,
      }),
  ],
});

export const incrementSubStep = () => ({
  type: INCREMENT_STEP,
});

export const decrementSubStep = () => ({
  type: DECREMENT_STEP,
});

export const setVariation = ({ variation }) => ({
  type: SET_VARIATION,
  variation,
});
