import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import PartnerCard from './PartnerCard';

const PartnerCardGrid = ({ partnerInfo }) => {
  const items = [];

  partnerInfo.forEach(partner => {
    items.push(
      <Col
        key={partner.name}
        sm={12}
        md={6}
        lg={4}
        style={{ paddingBottom: '30px' }}
      >
        <PartnerCard
          logo={partner.logo}
          badgeText={partner.badgeText}
          name={partner.name}
          industry={partner.industry}
          description={partner.description}
          link={partner.link}
        />
      </Col>
    );
  });

  return <Row>{items}</Row>;
};

PartnerCardGrid.propTypes = {
  partnerInfo: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))
    .isRequired,
};

export default PartnerCardGrid;
