import { connect } from 'react-redux';
import BankVerifiedPayBillPageWithLoader from './BankVerifiedPayBillPageWithLoader';
import { generateRandomBillAmount } from '../../../../../redux/modules/move-money';

const mapStateToProps = state => ({
  theme: state.general.theme,
  customerId: state.general.offlineMode
    ? null
    : state.createCustomer.createdCustomer.id,
  billAmount: state.moveMoney.billAmount,
});

const mapDispatchToProps = {
  generateRandomBillAmount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BankVerifiedPayBillPageWithLoader);
