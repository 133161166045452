import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styles from './AppBuilderPage.module.scss';
import PageNav from '../../molecules/page-nav/PageNav';
import { Heading, SubHeading } from '../../atoms/typography/Typography';
import BackgroundImage from '../../atoms/background-image/BackgroundImage';
import ThemePicker from '../../molecules/theme-picker/ThemePicker';
import PhoneInterface from '../../molecules/phone-interface/PhoneInterface';

import startInsurance from '../../../assets/images/phone-interface/start-screen-insurance.png';
import startMarketplace from '../../../assets/images/phone-interface/start-screen-marketplace.png';
import startPersonalFinance from '../../../assets/images/phone-interface/start-screen-personal-finance.png';
import startRental from '../../../assets/images/phone-interface/start-screen-rental.png';
import startUnbranded from '../../../assets/images/phone-interface/start-screen-unbranded.png';
import config from '../../../config';

const startScreens = {
  '': startUnbranded,
  insurance: startInsurance,
  marketplace: startMarketplace,
  personalFinance: startPersonalFinance,
  rental: startRental,
};

const AppBuilderPage = ({ theme, setTheme, PageHeading, PageSubHeading }) => {
  useEffect(() => {
    Object.keys(startScreens).forEach(key => {
      new Image().src = startScreens[key];
    });
  }, []);

  return (
    <div className="app-builder">
      <Container className="container__center-content">
        <div className={styles.content}>
          <div className={styles.themePickerWrap}>
            <PageHeading />
            <PageSubHeading />
            <ThemePicker initialValue={theme} changeHandler={setTheme} />
          </div>

          <div className={styles.phoneWrap}>
            <PhoneInterface position="right">
              <BackgroundImage
                src={startScreens[theme]}
                alt={`${theme} example interface`}
                className={styles.phoneImage}
              >
                {theme && (
                  <Link
                    to={config.tourFlow.appBuilder.next}
                    className={styles.imageLink}
                  />
                )}
              </BackgroundImage>
            </PhoneInterface>
          </div>
        </div>
      </Container>

      <PageNav
        prevLink={config.tourFlow.appBuilder.back}
        nextLink={config.tourFlow.appBuilder.next}
        bg="gradient"
        nextDisabled={!theme}
      />
    </div>
  );
};

AppBuilderPage.propTypes = {
  theme: PropTypes.string.isRequired,
  setTheme: PropTypes.func.isRequired,
  PageHeading: PropTypes.func,
  PageSubHeading: PropTypes.func,
};

AppBuilderPage.defaultProps = {
  PageHeading: () => <Heading>Build Your App on the Dwolla API</Heading>,
  PageSubHeading: () => (
    <SubHeading>Choose branding for your demo application.</SubHeading>
  ),
};

export default AppBuilderPage;
