import React from 'react';
import PropTypes from 'prop-types';
import PhonePage from '../../../phone-page/PhonePage';
import PageNav from '../../../../molecules/page-nav/PageNav';
import BankVerifiedSendToFriendScreen from '../../../phone-screens/2-attach-bank/BankVerifiedSendToFriendScreen';
import BankVerifiedPayBillScreen from '../../../phone-screens/2-attach-bank/BankVerifiedPayBillScreen';
import OkResponseCode from '../../../../molecules/code/OkResponseCode';
import AttachBankAccountDashboard from '../../../../molecules/dashboard-view/AttachBankAccountDashboard';
import { themeOptions } from '../../../../../utils/propTypeHelper';
import config from '../../../../../config';

const BankVerifiedPage = ({
  theme,
  customerId,
  inviterFirstName,
  inviterLastName,
  billAmount,
}) => {
  return (
    <PhonePage
      theme={theme}
      stepPercentage={100}
      currentStep={2}
      responseCodeComponent={<OkResponseCode />}
      dashboardComponent={
        <AttachBankAccountDashboard customerId={customerId} />
      }
      pageNavComponent={
        <PageNav
          prevLink={config.tourFlow.attachBank.back}
          nextLink={config.tourFlow.attachBank.next}
        />
      }
    >
      {inviterFirstName ? (
        <BankVerifiedSendToFriendScreen
          theme={theme}
          nextLink={config.tourFlow.attachBank.next}
          successNotificationText="Bank verified!"
          customerName={`${inviterFirstName} ${inviterLastName}`}
        />
      ) : (
        <BankVerifiedPayBillScreen
          theme={theme}
          nextLink={config.tourFlow.attachBank.next}
          successNotificationText="Bank verified!"
          billAmount={billAmount}
        />
      )}
    </PhonePage>
  );
};

BankVerifiedPage.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  customerId: PropTypes.string.isRequired,
  inviterFirstName: PropTypes.string.isRequired,
  inviterLastName: PropTypes.string.isRequired,
  billAmount: PropTypes.string.isRequired,
};

export default BankVerifiedPage;
