// eslint-disable-next-line import/no-cycle
import fundsFlows from '../../utils/flow-control/funds-flows';
import { randomUuid } from '../../utils/id-generator';
import { get } from '../../utils/api';
import {
  ATTACH_BANK_FAILURE,
  ATTACH_BANK_SUCCESS,
  ATTACH_PLAID_BANK_FAILURE,
  VERIFY_BANK_FAILURE,
} from './attach-bank';

export const SUBMIT_LOAD_FUNDING_SOURCES = 'SUBMIT_LOAD_FUNDING_SOURCES';
export const LOAD_FUNDING_SOURCES_SUCCESS = 'LOAD_FUNDING_SOURCES_SUCCESS';
export const LOAD_ACCOUNT_FUNDING_SOURCES_FAILURE =
  'LOAD_ACCOUNT_FUNDING_SOURCES_FAILURE';
export const LOAD_CUSTOMER_FUNDING_SOURCES_FAILURE =
  'LOAD_CUSTOMER_FUNDING_SOURCES_FAILURE';

const initialState = {
  loading: true,
  fundingSources: [],
  createdFundingSources: [],
};

const generateFakeFundingSource = (name, status = 'unverified') => {
  const id = randomUuid();
  return {
    _links: {
      self: {
        href: `https://api-sandbox.dwolla.com/funding-sources/${id}`,
      },
    },
    type: 'bank',
    id,
    name,
    status,
  };
};

const addBalanceTo = (createdFundingSources, balance = '0') => [
  ...createdFundingSources,
  {
    ...generateFakeFundingSource('Balance'),
    type: 'balance',
    status: 'verified',
    balance,
  },
];

const generateFakeFundingSources = (createdFundingSources, fundsFlow) => {
  if (!fundsFlow || !fundsFlows[fundsFlow].createsVerifiedCustomer)
    return createdFundingSources;

  return addBalanceTo(createdFundingSources);
};

export const accountFundingSources = addBalanceTo(
  [generateFakeFundingSource('Business Checking')],
  '5432.10'
);

const verifyFundingSource = (fundingSourceUrl, fundingSources) => {
  const bank = fundingSources.find(
    fs => fs._links.self.href === fundingSourceUrl
  );

  return [
    {
      ...bank,
      status: 'verified',
    },
    ...fundingSources.filter(fs => fs._links.self.href !== fundingSourceUrl),
  ];
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ATTACH_BANK_SUCCESS:
      return {
        ...state,
        createdFundingSources: [...state.createdFundingSources, action.body],
      };
    case ATTACH_BANK_FAILURE:
    case ATTACH_PLAID_BANK_FAILURE:
      return {
        ...state,
        createdFundingSources: [
          ...state.createdFundingSources,
          generateFakeFundingSource(
            action.name,
            action.type === ATTACH_PLAID_BANK_FAILURE
              ? 'verified'
              : 'unverified'
          ),
        ],
      };
    case SUBMIT_LOAD_FUNDING_SOURCES:
      return {
        ...state,
        loading: true,
      };
    case LOAD_FUNDING_SOURCES_SUCCESS:
      return {
        ...state,
        loading: false,
        fundingSources: action.body,
      };
    case LOAD_ACCOUNT_FUNDING_SOURCES_FAILURE:
      return {
        ...state,
        loading: false,
        fundingSources: accountFundingSources,
      };
    case LOAD_CUSTOMER_FUNDING_SOURCES_FAILURE:
      return {
        ...state,
        loading: false,
        fundingSources: generateFakeFundingSources(
          state.createdFundingSources,
          action.fundsFlow
        ),
      };
    case VERIFY_BANK_FAILURE:
      return {
        ...state,
        createdFundingSources: verifyFundingSource(
          action.fundingSourceUrl,
          state.createdFundingSources
        ),
      };
    default:
      return state;
  }
}

export const submitLoadAccountFundingSources = () => ({
  types: [
    SUBMIT_LOAD_FUNDING_SOURCES,
    LOAD_FUNDING_SOURCES_SUCCESS,
    LOAD_ACCOUNT_FUNDING_SOURCES_FAILURE,
  ],
  callAPI: () => get('/account/funding-sources'),
});

export const submitLoadCustomerFundingSources = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { fundsFlow } = state.general;
    const customerUrl = state.createCustomer.createdCustomer._links.self.href;
    dispatch({
      types: [
        SUBMIT_LOAD_FUNDING_SOURCES,
        LOAD_FUNDING_SOURCES_SUCCESS,
        LOAD_CUSTOMER_FUNDING_SOURCES_FAILURE,
      ],
      payload: {
        fundsFlow,
      },
      callAPI: () =>
        get(`/funding-sources?href=${encodeURIComponent(customerUrl)}`),
    });
  };
};
