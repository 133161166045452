import React from 'react';
import { Container } from 'react-bootstrap';
import PageNav from '../../molecules/page-nav/PageNav';
import Layers from '../../molecules/layers/Layers';
import styles from './HomePage.module.scss';
import config from '../../../config';

const HomePage = () => (
  <div className={styles.homePage}>
    <Container className="container__center-content">
      <div className={styles.content}>
        <Layers playExpandingAnimation showLabels />
      </div>
    </Container>

    <PageNav
      nextLink={config.tourFlow.home.next}
      nextText="Start the Tour"
      bg="gradient"
    />
  </div>
);

export default HomePage;
