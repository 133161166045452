import { connect } from 'react-redux';
import { setDemoTrack } from '../../../redux/modules/general';
import SelectDemoTrackPage from './SelectDemoTrackPage';

const mapStateToProps = state => ({
  demoTrack: state.general.demoTrack,
});

const mapDispatchToProps = {
  setDemoTrack,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectDemoTrackPage);
