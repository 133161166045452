import { connect } from 'react-redux';
import FundingSourcesPage from './FundingSourcesPage';
// eslint-disable-next-line import/no-cycle
import { submitLoadCustomerFundingSources } from '../../../../../redux/modules/funding-sources';
import config from '../../../../../config';

const mapStateToProps = state => ({
  theme: state.general.theme,
  loading: state.fundingSources.loading,
  customerId: state.general.offlineMode
    ? null
    : state.createCustomer.createdCustomer.id,
  fundingSourceUrl:
    state.fundingSources.createdFundingSources[1]._links.self.href,
  fundingSources: state.fundingSources.fundingSources,
  transferLink: config.tourFlow.attachBank.next,
});

const mapDispatchToProps = {
  submitLoadFundingSources: submitLoadCustomerFundingSources,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FundingSourcesPage);
