import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import ui from '../../../assets/images/layers/ui-rally-rd-layer.png';
import api from '../../../assets/images/layers/dwolla-api-layer.jpg';
import ecosystem from '../../../assets/images/layers/ecosystem-layer.png';
import placeholder from '../../../assets/images/layers/placeholder-layer.png';

import layerStyles from './Layers.module.scss';
import labelStyles from './Labels.module.scss';

import Labels from './Labels';
import LayerImage from './LayerImage';

const Layers = ({
  layerWidth,
  layerHeight,
  selectedDemoTrack,
  playExpandingAnimation,
  playTransitionAnimation,
  showLabels,
}) => {
  const [visibilityTogglingDisabled, setVisibilityTogglingDisabled] = useState(
    true
  );

  useEffect(() => {
    let timeoutId;
    if (playTransitionAnimation) {
      timeoutId = setTimeout(() => setVisibilityTogglingDisabled(false), 1300);
    }

    return function cleanup() {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  });

  const componentClasses = classNames({
    [layerStyles.layersComponent]: true,
    [layerStyles.expanding]: playExpandingAnimation,
    [labelStyles.expanding]: playExpandingAnimation,
    [layerStyles.sliding]: playTransitionAnimation,
  });

  return (
    <div className={componentClasses}>
      <div className={layerStyles.layerWrap}>
        {showLabels && <Labels />}

        <div
          className={layerStyles.imageWrap}
          style={{ width: layerWidth, height: layerHeight }}
        >
          <LayerImage src={placeholder} imagePosition="Back" />
          <LayerImage
            src={ecosystem}
            alt="Payments Ecosystem Layer"
            imagePosition="Back"
            visibleWhenDemoTrack="app"
            selectedDemoTrack={selectedDemoTrack}
            visibilityTogglingDisabled={
              playTransitionAnimation ? visibilityTogglingDisabled : false
            }
          />
          <LayerImage src={api} alt="Dwolla API Layer" imagePosition="Middle" />
          <LayerImage src={placeholder} imagePosition="Front" />
          <LayerImage
            src={ui}
            alt="Your Interface Layer"
            imagePosition="Front"
            visibleWhenDemoTrack="integration"
            selectedDemoTrack={selectedDemoTrack}
            visibilityTogglingDisabled={
              playTransitionAnimation ? visibilityTogglingDisabled : false
            }
          />
        </div>
      </div>

      {selectedDemoTrack && (
        <div
          className={layerStyles.focusedWrap}
          style={{ width: layerWidth, height: layerHeight }}
        >
          <LayerImage
            src={ui}
            alt="Your Interface Layer"
            imagePosition="Focused"
            visibleWhenDemoTrack="app"
            selectedDemoTrack={selectedDemoTrack}
          />
          <LayerImage
            src={ecosystem}
            alt="Payments Ecosystem Layer"
            imagePosition="Focused"
            visibleWhenDemoTrack="integration"
            selectedDemoTrack={selectedDemoTrack}
          />
        </div>
      )}
    </div>
  );
};

const defaultWidth = 291;

Layers.propTypes = {
  layerWidth: PropTypes.string,
  layerHeight: PropTypes.string,
  selectedDemoTrack: PropTypes.string,
  playExpandingAnimation: PropTypes.bool,
  playTransitionAnimation: PropTypes.bool,
  showLabels: PropTypes.bool,
};

Layers.defaultProps = {
  layerWidth: `${defaultWidth}px`,
  layerHeight: `${defaultWidth * 1.775}px`,
  selectedDemoTrack: null,
  playExpandingAnimation: false,
  playTransitionAnimation: false,
  showLabels: false,
};

export default Layers;
