import React from 'react';
import PropTypes from 'prop-types';
import styles from './StepOverview.module.scss';

const StepOverview = ({ steps }) => (
  <div className={styles.steps}>
    {steps.map((step, index) => (
      <>
        <div className={styles.stepNumber}>
          <div className={styles.stepCircle}>{index + 1}</div>
          {index + 1 < steps.length && <div className={styles.stepLine} />}
        </div>
        <div className={styles.stepText}>
          <h3 className={styles.stepName}>{step.name}</h3>
          <div className={styles.stepDescription}>{step.description}</div>
        </div>
      </>
    ))}
  </div>
);

StepOverview.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
};

export default StepOverview;
