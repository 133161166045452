/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PhonePage from '../../../phone-page/PhonePage';
import { themeOptions } from '../../../../../utils/propTypeHelper';
import VerifyBankWithPlaidScreen from '../../../phone-screens/2-attach-bank/VerifyBankWithPlaidScreen';
import PlaidBankRequestCode from '../../../../molecules/code/PlaidBankRequestCode';
import PageNav from '../../../../molecules/page-nav/PageNav';
import PlaidCredentialsNote from '../../../../atoms/note/PlaidCredentialsNote';

const CreatePlaidVerifiedBankPage = ({
  theme,
  stepPercentage,
  bearerToken,
  customerUrl,
  pending,
  plaidToken,
  submitPlaidTokenExchange,
  handleBackClicked,
}) => {
  const [publicToken, setPublicToken] = useState('');
  const [accountName, setAccountName] = useState('');

  const handleSuccess = (token, metadata) => {
    const plaidAccount = metadata.accounts[0];
    setPublicToken(token);
    setAccountName(plaidAccount.name);

    submitPlaidTokenExchange({
      customerUrl,
      publicToken: token,
      accountId: plaidAccount.id,
      name: plaidAccount.name,
    });
  };

  return (
    <PhonePage
      theme={theme}
      stepPercentage={stepPercentage}
      currentStep={2}
      processing={pending}
      disablePhoneScrolling={window.navigator.userAgent.indexOf('Edge') > -1}
      requestCodeComponent={
        <PlaidBankRequestCode
          {...{
            customerUrl,
            plaidToken,
            name: accountName,
            bearerToken,
          }}
        />
      }
      noteComponent={<PlaidCredentialsNote />}
      pageNavComponent={
        <PageNav
          prevOnClick={handleBackClicked}
          nextOnClick={e => e.preventDefault()}
          nextText="Send to API"
          nextPending={pending}
          nextDisabled
        />
      }
    >
      <VerifyBankWithPlaidScreen
        {...{
          theme,
          publicToken,
          handleSuccess,
        }}
      />
    </PhonePage>
  );
};

CreatePlaidVerifiedBankPage.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  stepPercentage: PropTypes.number,
  bearerToken: PropTypes.string.isRequired,
  customerUrl: PropTypes.string.isRequired,
  pending: PropTypes.bool.isRequired,
  plaidToken: PropTypes.string,
  submitPlaidTokenExchange: PropTypes.func.isRequired,
  handleBackClicked: PropTypes.func.isRequired,
};

CreatePlaidVerifiedBankPage.defaultProps = {
  stepPercentage: 50,
  plaidToken: '',
};

export default CreatePlaidVerifiedBankPage;
