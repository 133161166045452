/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import useFocusOnPageLoad from '../../../../../hooks/focus-on-page-load';
import {
  isPopulated,
  isValidRoutingNumber,
} from '../../../../../utils/validators';
import PhonePage from '../../../phone-page/PhonePage';
import UnverifiedBankRequestCode from '../../../../molecules/code/UnverifiedBankRequestCode';
import { themeOptions } from '../../../../../utils/propTypeHelper';
import UnverifiedBankScreen from '../../../phone-screens/2-attach-bank/UnverifiedBankScreen';
import {
  slowFill,
  slowFillSingle,
  chance,
} from '../../../../../utils/autofill';
import PageNavWithAutoFill from '../../../../molecules/page-nav/PageNavWithAutofill';
import config from '../../../../../config';

const CreateUnverifiedBankPage = ({
  theme,
  stepPercentage,
  bearerToken,
  customerUrl,
  pending,
  submitAttachBank,
  handleBackClicked,
}) => {
  const [bankAccountType, setBankAccountType] = useState('checking');
  const [routingNumber, setRoutingNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountAgain, setAccountAgain] = useState('');
  const [name, setName] = useState('');

  const elToFocus = useRef(null);
  useFocusOnPageLoad(elToFocus);

  const isValidInput =
    !pending &&
    (bankAccountType === 'checking' || bankAccountType === 'savings') &&
    isPopulated(accountNumber) &&
    accountNumber === accountAgain &&
    isValidRoutingNumber(routingNumber) &&
    isPopulated(name);

  const handleSubmitClicked = e => {
    e.preventDefault();
    submitAttachBank({
      customerUrl,
      bankAccountType,
      routingNumber,
      accountNumber,
      name,
    });
  };

  const handleAutoFill = () => {
    setRoutingNumber('');
    setAccountNumber('');
    setAccountAgain('');
    setName('');

    const t = chance.pickone(['checking', 'savings']);
    const an = chance.natural({ min: 1, max: 999999 }).toString();

    slowFillSingle(t, setBankAccountType)
      .then(() => slowFill('222222226', setRoutingNumber))
      .then(() => slowFill(an, setAccountNumber))
      .then(() => slowFill(an, setAccountAgain))
      .then(() =>
        slowFill(chance.bankAccountName(chance.capitalize(t)), setName)
      );
  };

  return (
    <PhonePage
      theme={theme}
      stepPercentage={stepPercentage}
      currentStep={2}
      processing={pending}
      requestCodeComponent={
        <UnverifiedBankRequestCode
          {...{
            customerUrl,
            routingNumber,
            accountNumber,
            bankAccountType,
            name,
            bearerToken,
          }}
        />
      }
      pageNavComponent={
        <PageNavWithAutoFill
          {...{
            prevOnClick: handleBackClicked,
            prevLink: config.tourFlow.attachBank.back,
            isValidInput,
            pending,
            handleAutoFill,
            handleSubmitClicked,
          }}
        />
      }
    >
      <UnverifiedBankScreen
        {...{
          theme,
          isValidInput,
          pending,
          elToFocus,
          bankAccountType,
          setBankAccountType,
          routingNumber,
          setRoutingNumber,
          accountNumber,
          setAccountNumber,
          accountAgain,
          setAccountAgain,
          name,
          setName,
          handleSubmitClicked,
        }}
      />
    </PhonePage>
  );
};

CreateUnverifiedBankPage.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  stepPercentage: PropTypes.number,
  bearerToken: PropTypes.string.isRequired,
  customerUrl: PropTypes.string.isRequired,
  pending: PropTypes.bool.isRequired,
  submitAttachBank: PropTypes.func.isRequired,
  handleBackClicked: PropTypes.func.isRequired,
};

CreateUnverifiedBankPage.defaultProps = {
  stepPercentage: 50,
};

export default CreateUnverifiedBankPage;
