import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PhonePage from '../../../phone-page/PhonePage';
import PageNav from '../../../../molecules/page-nav/PageNav';
import CreateUserDashboard from '../../../../molecules/dashboard-view/CreateUserDashboard';
import CreatedResponseCode from '../../../../molecules/code/CreatedResponseCode';
import { themeOptions } from '../../../../../utils/propTypeHelper';
import CustomerCreatedScreen from '../../../phone-screens/1-create-users/CustomerCreatedScreen';
import bubbleSteps from '../../../../../utils/flow-control/bubble-flows';
import config from '../../../../../config';

const CustomerCreatedPage = ({
  theme,
  message,
  customerUrl,
  customerId,
  setBubbleSteps,
}) => {
  useEffect(() => {
    setBubbleSteps(bubbleSteps.customerCreated);
  }, [setBubbleSteps]);

  return (
    <PhonePage
      theme={theme}
      stepPercentage={100}
      currentStep={1}
      responseCodeComponent={<CreatedResponseCode resourceUrl={customerUrl} />}
      dashboardComponent={<CreateUserDashboard customerId={customerId} />}
      pageNavComponent={
        <PageNav
          prevLink={config.tourFlow.createUsers.back}
          nextLink={config.tourFlow.createUsers.next}
        />
      }
    >
      <CustomerCreatedScreen
        theme={theme}
        message={message}
        nextLink={config.tourFlow.createUsers.next}
      />
    </PhonePage>
  );
};

CustomerCreatedPage.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  message: PropTypes.string,
  customerUrl: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
  setBubbleSteps: PropTypes.func.isRequired,
};

CustomerCreatedPage.defaultProps = {
  message: "Now let's get your bank account hooked up.",
};

export default CustomerCreatedPage;
