import { connect } from 'react-redux';
import { setBubbleSteps } from '../../../../../redux/modules/tour-bubbles';
import CustomerCreatedPage from './CustomerCreatedPage';

const mapStateToProps = state => ({
  theme: state.general.theme,
  customerUrl: state.createCustomer.createdCustomer._links.self.href,
  customerId: state.general.offlineMode
    ? null
    : state.createCustomer.createdCustomer.id,
});

const mapDispatchToProps = {
  setBubbleSteps,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerCreatedPage);
