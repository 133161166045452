import React from 'react';
import PropTypes from 'prop-types';
import styles from './PartnerCard.module.scss';
import TextBadge from '../../atoms/text-badge/TextBadge';

const PartnerCard = ({
  logo,
  badgeText,
  name,
  industry,
  description,
  link,
}) => (
  <div className={styles.partnerCardComponent}>
    <div className={styles.logoWrap}>
      <img src={logo} alt={name} className={styles.logo} />
      {badgeText && (
        <div className={styles.badgeWrap}>
          <TextBadge>{badgeText}</TextBadge>
        </div>
      )}
    </div>

    <div className={styles.name}>{name}</div>
    <div className={styles.industry}>{industry}</div>
    <div className={styles.description}>{description}</div>
    {link && (
      <div className={styles.link}>
        <a href={link} target="_blank" rel="noopener noreferrer">
          Learn More
        </a>
      </div>
    )}
  </div>
);

PartnerCard.propTypes = {
  logo: PropTypes.string.isRequired,
  badgeText: PropTypes.string,
  name: PropTypes.string.isRequired,
  industry: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string,
};

PartnerCard.defaultProps = {
  badgeText: null,
  link: null,
};

export default PartnerCard;
