import { randomUuid } from '../../utils/id-generator';
import { postJson, get } from '../../utils/api';
import { chance } from '../../utils/autofill';

export const SUBMIT_LOAD_ACCOUNT = 'SUBMIT_LOAD_ACCOUNT';
export const LOAD_ACCOUNT_SUCCESS = 'LOAD_ACCOUNT_SUCCESS';
export const LOAD_ACCOUNT_FAILURE = 'LOAD_ACCOUNT_FAILURE';

export const SUBMIT_MOVE_MONEY = 'SUBMIT_MOVE_MONEY';
export const MOVE_MONEY_SUCCESS = 'MOVE_MONEY_SUCCESS';
export const MOVE_MONEY_FAILURE = 'MOVE_MONEY_FAILURE';

export const SET_RANDOM_BILL_AMOUNT = 'SET_RANDOM_BILL_AMOUNT';

const initialState = {
  subStep: 0,
  loadingAcct: true,
  pending: false,
  account: {},
  billAmount: 0,
  createdTransfer: {},
};

const generateFakeTransfer = amount => {
  const id = randomUuid();
  return {
    _links: {
      self: {
        href: `https://api-sandbox.dwolla.com/transfers/${id}`,
      },
    },
    id,
    amount: {
      value: amount,
      currency: 'USD',
    },
  };
};

const generateFakeAccount = () => ({
  selfLink:
    'https://api-sandbox.dwolla.com/accounts/72693c59-e2ad-422d-992c-5cb9ce3cc326',
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_LOAD_ACCOUNT:
      return {
        ...state,
        loadingAcct: true,
      };
    case LOAD_ACCOUNT_SUCCESS:
      return {
        ...state,
        loadingAcct: false,
        account: action.body,
      };
    case LOAD_ACCOUNT_FAILURE:
      return {
        ...state,
        loadingAcct: false,
        account: generateFakeAccount(),
      };
    case SUBMIT_MOVE_MONEY:
      return {
        ...state,
        pending: true,
      };
    case MOVE_MONEY_SUCCESS:
      return {
        ...state,
        subStep: 1,
        pending: false,
        createdTransfer: action.body,
      };
    case MOVE_MONEY_FAILURE:
      return {
        ...state,
        subStep: 1,
        pending: false,
        createdTransfer: generateFakeTransfer(action.amount),
      };
    case SET_RANDOM_BILL_AMOUNT:
      return {
        ...state,
        billAmount: chance.integer({ min: 30, max: 500 }),
      };
    default:
      return state;
  }
}

export const submitLoadAccount = () => ({
  types: [SUBMIT_LOAD_ACCOUNT, LOAD_ACCOUNT_SUCCESS, LOAD_ACCOUNT_FAILURE],
  callAPI: () => get('/account'),
});

export const submitMoveMoney = ({ sourceUrl, destinationUrl, amount }) => ({
  types: [SUBMIT_MOVE_MONEY, MOVE_MONEY_SUCCESS, MOVE_MONEY_FAILURE],
  payload: { amount },
  callAPI: () =>
    postJson('/transfers', {
      sourceLink: sourceUrl,
      destinationLink: destinationUrl,
      amount,
    }),
});

export const generateRandomBillAmount = () => ({
  type: SET_RANDOM_BILL_AMOUNT,
});
