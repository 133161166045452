import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './Layers.module.scss';

const LayerImage = ({
  src,
  alt,
  imagePosition,
  visibleWhenDemoTrack,
  selectedDemoTrack,
  visibilityTogglingDisabled,
}) => {
  const setLayerVisibility = visibleDemoTrack => {
    if (!selectedDemoTrack || !visibleDemoTrack || visibilityTogglingDisabled)
      return '';
    return styles[
      selectedDemoTrack === visibleDemoTrack ? 'fadedIn' : 'fadedOut'
    ];
  };

  return (
    <img
      src={src}
      alt={alt}
      className={classNames(
        styles[`image${imagePosition}`],
        setLayerVisibility(visibleWhenDemoTrack)
      )}
    />
  );
};

LayerImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  imagePosition: PropTypes.string.isRequired,
  visibleWhenDemoTrack: PropTypes.string,
  selectedDemoTrack: PropTypes.string,
  visibilityTogglingDisabled: PropTypes.bool,
};

LayerImage.defaultProps = {
  alt: '',
  visibleWhenDemoTrack: '',
  selectedDemoTrack: null,
  visibilityTogglingDisabled: false,
};

export default LayerImage;
