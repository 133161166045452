import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import PageNav from '../../molecules/page-nav/PageNav';
import VideoLoopSelector from '../../molecules/video-loop-selector/VideoLoopSelector';
import { Heading, SubHeading } from '../../atoms/typography/Typography';
import fundsFlows from '../../../utils/flow-control/funds-flows';
import { flowOptions } from '../../../utils/video-options';
import config from '../../../config';

const FundsFlowsPage = ({
  fundsFlow,
  setFundsFlow,
  fundsFlowSetByUser,
  PageHeading,
  PageSubHeading,
}) => {
  useEffect(() => {
    fundsFlowSetByUser();
  }, [fundsFlowSetByUser]);

  return (
    <div className="text-center">
      <Container className="container__center-content">
        <PageHeading />
        <PageSubHeading />

        <VideoLoopSelector
          videoOptions={flowOptions}
          selectedVideo={fundsFlow}
          setSelectedVideo={setFundsFlow}
        />
      </Container>

      <PageNav
        prevLink={config.tourFlow.fundsFlows.back}
        nextLink={config.tourFlow.fundsFlows.next}
        bg="gradient"
        nextDisabled={!fundsFlows[fundsFlow].enabled}
      />
    </div>
  );
};

FundsFlowsPage.propTypes = {
  fundsFlow: PropTypes.string.isRequired,
  setFundsFlow: PropTypes.func.isRequired,
  fundsFlowSetByUser: PropTypes.func.isRequired,
  PageHeading: PropTypes.func,
  PageSubHeading: PropTypes.func,
};

FundsFlowsPage.defaultProps = {
  PageHeading: () => <Heading>Build Your App on the Dwolla API</Heading>,
  PageSubHeading: () => <SubHeading>Available Funds Flows</SubHeading>,
};

export default FundsFlowsPage;
