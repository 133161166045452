export const tourStops = {
  home: '/',
  selectTrack: '/select-track',
  fundsFlows: '/funds-flows',
  integrationScopes: '/integration-scopes',
  stepOverview: '/step-overview',
  appBuilder: '/app-builder',
  createUsers: '/create-users',
  attachBank: '/attach-bank',
  moveMoney: '/move-money',
  partners: '/dwolla-partners',
  conclusion: '/conclusion',
};

export const calcStops = {
  calcPayments: '/payments',
  calcPersonalInfo: '/personal-info',
  calcResults: '/results',
};

const isAppTrack = demoTrack => demoTrack === 'app';

export const selfServiceFlow = {
  home: {
    next: tourStops.fundsFlows,
  },
  fundsFlows: {
    back: tourStops.home,
    next: tourStops.appBuilder,
  },
  appBuilder: {
    back: tourStops.fundsFlows,
    next: tourStops.stepOverview,
  },
  stepOverview: {
    back: tourStops.appBuilder,
    next: tourStops.createUsers,
  },
  createUsers: {
    back: tourStops.stepOverview,
    next: tourStops.attachBank,
  },
  attachBank: {
    back: tourStops.createUsers,
    next: tourStops.moveMoney,
  },
  moveMoney: {
    back: tourStops.attachBank,
    next: tourStops.partners,
  },
  partners: {
    back: () => tourStops.moveMoney,
    next: tourStops.conclusion,
  },
  conclusion: {
    back: tourStops.partners,
  },
};

export const boothFlow = {
  ...selfServiceFlow,
  home: {
    next: tourStops.selectTrack,
  },
  selectTrack: {
    back: tourStops.home,
    next: demoTrack =>
      isAppTrack(demoTrack)
        ? tourStops.fundsFlows
        : tourStops.integrationScopes,
  },
  fundsFlows: {
    back: tourStops.selectTrack,
    next: tourStops.appBuilder,
  },
  integrationScopes: {
    back: tourStops.selectTrack,
    next: tourStops.partners,
  },
  appBuilder: {
    back: tourStops.fundsFlows,
    next: tourStops.createUsers,
  },
  createUsers: {
    back: tourStops.appBuilder,
    next: tourStops.attachBank,
  },
  partners: {
    back: demoTrack =>
      isAppTrack(demoTrack) ? tourStops.moveMoney : tourStops.integrationScopes,
    next: tourStops.conclusion,
  },
};

export const calculatorFlow = {
  home: {
    next: calcStops.calcPayments,
  },
  calcPayments: {
    back: tourStops.home,
    next: calcStops.calcResults,
  },
  calcResults: {
    back: calcStops.calcPayments,
  },
};

export const getTestFlow = () => {
  const testFlow = {
    ...selfServiceFlow,
    ...boothFlow,
    ...calculatorFlow,
  };

  Object.keys(testFlow).forEach(key => {
    testFlow[key].back =
      typeof testFlow[key].back === 'function' ? () => '/' : '/';
    testFlow[key].next =
      typeof testFlow[key].next === 'function' ? () => '/' : '/';
  });

  return testFlow;
};
