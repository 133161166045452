import { connect } from 'react-redux';
import getPageVariation from '../../../utils/page-variations';
import config from '../../../config';

const mapStateToProps = state => ({
  demoTrack: state.general.demoTrack,
});

export default connect(mapStateToProps)(
  getPageVariation('partnerCards', config.versionName)
);
