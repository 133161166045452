import fundsFlows from '../../utils/flow-control/funds-flows';
import {
  CREATE_CUSTOMER_FAILURE,
  LOAD_FACILITATOR_FAILURE,
} from './create-customer';
import {
  ATTACH_BANK_FAILURE,
  PLAID_EXCHANGE_FAILURE,
  VERIFY_BANK_FAILURE,
} from './attach-bank';
import { LOAD_ACCOUNT_FAILURE, MOVE_MONEY_FAILURE } from './move-money';
import {
  LOAD_ACCOUNT_FUNDING_SOURCES_FAILURE,
  LOAD_CUSTOMER_FUNDING_SOURCES_FAILURE,
} from './funding-sources';
import config from '../../config';

export const SET_DEMO_TRACK = 'SET_DEMO_TRACK';
export const SET_FUNDS_FLOW = 'SET_FUNDS_FLOW';
export const SET_THEME = 'SET_THEME';
export const FUNDS_FLOW_SET_BY_USER = 'FUNDS_FLOW_SET_BY_USER';

const initialState = {
  demoTrack: 'app',
  fundsFlow: fundsFlows.send.name,
  theme: 'personalFinance',
  isFundsFlowSetByUser: false,
  offlineMode: config.forceOfflineMode,
  bearerToken: '0Sn0W6kzNicvoWhDbQcVSKLRUpGjIdlPSEYyrHqrDDoRnQwE7Q',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_DEMO_TRACK:
      return {
        ...state,
        demoTrack: action.demoTrack,
      };
    case SET_FUNDS_FLOW:
      return {
        ...state,
        fundsFlow: action.flow,
      };
    case SET_THEME:
      return {
        ...state,
        theme: action.theme,
      };
    case FUNDS_FLOW_SET_BY_USER:
      return {
        ...state,
        isFundsFlowSetByUser: true,
      };
    case CREATE_CUSTOMER_FAILURE:
    case LOAD_FACILITATOR_FAILURE:
    case ATTACH_BANK_FAILURE:
    case VERIFY_BANK_FAILURE:
    case LOAD_ACCOUNT_FUNDING_SOURCES_FAILURE:
    case LOAD_CUSTOMER_FUNDING_SOURCES_FAILURE:
    case LOAD_ACCOUNT_FAILURE:
    case MOVE_MONEY_FAILURE:
    case PLAID_EXCHANGE_FAILURE:
      return {
        ...state,
        offlineMode: true,
      };
    default:
      return state;
  }
}

export const setDemoTrack = demoTrack => ({
  type: SET_DEMO_TRACK,
  demoTrack,
});

export const setFundsFlow = flow => ({
  type: SET_FUNDS_FLOW,
  flow,
});

export const setTheme = theme => ({
  type: SET_THEME,
  theme,
});

export const fundsFlowSetByUser = () => ({
  type: FUNDS_FLOW_SET_BY_USER,
});
