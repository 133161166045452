/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { Heading, SubHeading } from '../../atoms/typography/Typography';
import AppBuilderPage from './AppBuilderPage';

const SelfServiceAppBuilderPage = passThroughProps => (
  <AppBuilderPage
    {...passThroughProps}
    PageHeading={() => (
      <Heading variant="small">Build Your App on the Dwolla API</Heading>
    )}
    PageSubHeading={() => (
      <SubHeading variant="small">
        Because Dwolla is a white-labeled API, you have the ability to maintain
        your product and branding at the forefront of your user experience. We
        can’t showcase your brand as well as you, so choose from a few
        pre-populated examples for the purpose of this demo:
      </SubHeading>
    )}
  />
);

export default SelfServiceAppBuilderPage;
