import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './TextBadge.module.scss';

const TextBadge = ({ children, variant }) => (
  <div className={classNames(styles.textBadgeComponent, styles[variant])}>
    {children}
  </div>
);

TextBadge.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['gray']),
};

TextBadge.defaultProps = {
  variant: 'gray',
};

export default TextBadge;
