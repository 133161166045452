import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './Step.module.scss';
import checkmark from '../../../assets/images/progress-bar/icon-checkmark-sm.svg';

const Step = ({ stepNumber, stepLabel, stepStatus, variant }) => (
  <div className={styles.stepComponent}>
    <div
      className={classNames(
        styles.stepNumber,
        styles[stepStatus],
        styles[variant]
      )}
    >
      {stepStatus === 'complete' ? (
        <img
          src={checkmark}
          alt={`Step ${stepNumber} Complete`}
          className={styles.stepCheck}
        />
      ) : (
        stepNumber
      )}
    </div>
    <div className={classNames(`bubble-step-${stepNumber}`, styles.stepLabel)}>
      {stepLabel}
    </div>
  </div>
);

Step.propTypes = {
  stepNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stepLabel: PropTypes.string,
  stepStatus: PropTypes.string,
  variant: PropTypes.oneOf(['purple', 'gray']),
};

Step.defaultProps = {
  stepNumber: 'X',
  stepLabel: '',
  stepStatus: 'upcoming',
  variant: 'purple',
};

export default Step;
