import React from 'react';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Step from './Step';
import styles from './StepIndicator.module.scss';

const StepIndicator = ({ steps, currentStep, stepPercentage, variant }) => {
  const items = [];
  const getStepStatus = stepIndex => {
    const isStepCurrent = () => stepIndex === currentStep;
    const isStepPreviouslyCompleted = () => stepIndex < currentStep;
    const isStepCurrentAndComplete = () =>
      isStepCurrent() && stepPercentage === 100;

    if (isStepPreviouslyCompleted() || isStepCurrentAndComplete()) {
      return 'complete';
    }
    if (isStepCurrent()) {
      return 'current';
    }
    return 'upcoming';
  };

  steps.forEach((stepLabel, index) => {
    const stepIndex = index + 1;
    if (stepIndex > 1) {
      items.push(
        <div
          key={[stepIndex, 'Line']}
          className={classNames(styles.stepLine, styles[variant], {
            [styles.orange]: currentStep >= stepIndex,
          })}
        />
      );
    }
    items.push(
      <Step
        key={[stepIndex, 'Step']}
        stepNumber={stepIndex}
        stepLabel={stepLabel}
        stepStatus={getStepStatus(stepIndex)}
        variant={variant}
      />
    );
  });

  return (
    <Container className={styles.stepIndicatorComponent}>{items}</Container>
  );
};

StepIndicator.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentStep: PropTypes.number.isRequired,
  stepPercentage: PropTypes.number.isRequired,
  variant: PropTypes.oneOf(['purple', 'gray']),
};

StepIndicator.defaultProps = {
  variant: 'purple',
};

export default StepIndicator;
