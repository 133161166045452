import { connect } from 'react-redux';
import {
  submitCreateVerifiedCustomer,
  submitLoadFacilitatorCustomer,
} from '../../../../../redux/modules/create-customer';
import {
  setBubbleSteps,
  closeBubbles,
} from '../../../../../redux/modules/tour-bubbles';
import CreateVerifiedCustomerSendToFriendPageWithLoader from './CreateVerifiedCustomerSendToFriendPageWithLoader';

const mapStateToProps = state => ({
  theme: state.general.theme,
  bearerToken: state.general.bearerToken,
  loading: state.createCustomer.loading,
  pending: state.createCustomer.pending,
  heading: `${state.createCustomer.facilitatorCustomer.firstName} Invited You to Join`,
  areBubblesOpen: state.tourBubbles.areBubblesOpen,
});

const mapDispatchToProps = {
  submitCreateCustomer: submitCreateVerifiedCustomer,
  submitLoadFacilitatorCustomer,
  setBubbleSteps,
  closeBubbles,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateVerifiedCustomerSendToFriendPageWithLoader);
