import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
} from 'react-bootstrap';

const VideoLoopSelectionButtons = ({
  videoOptions,
  selectedVideo,
  setSelectedVideo,
}) => {
  const items = [];

  Object.keys(videoOptions).forEach((key, index) => {
    items.push(
      <ToggleButton
        key={[index, 'Video Loop Button']}
        value={key}
        variant="pill-gray"
      >
        {videoOptions[key].label}
      </ToggleButton>
    );
  });

  return (
    <ButtonToolbar>
      <ToggleButtonGroup
        type="radio"
        name="animation-selection"
        defaultValue={selectedVideo}
        onChange={setSelectedVideo}
      >
        {items}
      </ToggleButtonGroup>
    </ButtonToolbar>
  );
};

VideoLoopSelectionButtons.propTypes = {
  videoOptions: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string))
    .isRequired,
  selectedVideo: PropTypes.string.isRequired,
  setSelectedVideo: PropTypes.func.isRequired,
};

export default VideoLoopSelectionButtons;
