import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
} from 'react-bootstrap';

import styles from './ThemePicker.module.scss';
import { themeOptionsOptional } from '../../../utils/propTypeHelper';
import ThemePickerButton from './ThemePickerButton';

const ThemePicker = ({ initialValue, changeHandler }) => {
  return (
    <ButtonToolbar className={styles.buttonToolbar}>
      <ToggleButtonGroup
        type="radio"
        className={styles.buttons}
        name="themePicker"
        defaultValue={initialValue}
        onChange={changeHandler}
      >
        <ToggleButton value="personalFinance" variant="card">
          <ThemePickerButton
            theme="personalFinance"
            label="Personal Finance App"
          />
        </ToggleButton>
        <ToggleButton value="insurance" variant="card">
          <ThemePickerButton theme="insurance" label="Insurance App" />
        </ToggleButton>
        <ToggleButton value="marketplace" variant="card">
          <ThemePickerButton theme="marketplace" label="Marketplace App" />
        </ToggleButton>
        <ToggleButton value="rental" variant="card">
          <ThemePickerButton theme="rental" label="Rental App" />
        </ToggleButton>
      </ToggleButtonGroup>
    </ButtonToolbar>
  );
};

ThemePicker.propTypes = {
  initialValue: PropTypes.oneOf(themeOptionsOptional),
  changeHandler: PropTypes.func.isRequired,
};

ThemePicker.defaultProps = {
  initialValue: '',
};

export default ThemePicker;
