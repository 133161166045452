import React from 'react';
import PropTypes from 'prop-types';
import PageNav from './PageNav';

const PageNavWithAutoFill = ({
  prevLink,
  prevOnClick,
  isValidInput,
  loading,
  pending,
  handleAutoFill,
  handleSubmitClicked,
}) => (
  <PageNav
    prevLink={prevLink}
    prevOnClick={prevOnClick}
    nextText={isValidInput ? 'Send to API' : 'Autofill Fields'}
    nextOnClick={isValidInput ? handleSubmitClicked : handleAutoFill}
    nextPending={pending}
    nextDisabled={loading}
  />
);

PageNavWithAutoFill.propTypes = {
  prevLink: PropTypes.string,
  prevOnClick: PropTypes.func,
  isValidInput: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  pending: PropTypes.bool.isRequired,
  handleAutoFill: PropTypes.func.isRequired,
  handleSubmitClicked: PropTypes.func.isRequired,
};

PageNavWithAutoFill.defaultProps = {
  prevLink: '',
  prevOnClick: null,
  loading: false,
};

export default PageNavWithAutoFill;
