/* eslint-disable import/no-cycle */
import React from 'react';

import CreateUnverifiedCustomerPageConnection from '../../components/organisms/phone-pages/1-create-users/create-unverified-customer-page/CreateUnverifiedCustomerPageConnection';
import CreateVerifiedCustomerPageConnection from '../../components/organisms/phone-pages/1-create-users/create-verified-customer-page/CreateVerifiedCustomerPageConnection';
import CreateVerifiedCustomerSendToFriendPageConnection from '../../components/organisms/phone-pages/1-create-users/create-verified-customer-page/CreateVerifiedCustomerSendToFriendPageConnection';
import CustomerCreatedPageConnection from '../../components/organisms/phone-pages/1-create-users/customer-created-page/CustomerCreatedPageConnection';

import CreateUnverifiedBankPageConnection from '../../components/organisms/phone-pages/2-attach-bank/create-unverified-bank-page/CreateUnverifiedBankPageConnection';
import UnverifiedBankAttachedPayoutsPageConnection from '../../components/organisms/phone-pages/2-attach-bank/unverified-bank-attached-page/UnverifiedBankAttachedPayoutsPageConnection';
import ChooseBankMethodPageConnection from '../../components/organisms/phone-pages/2-attach-bank/choose-bank-method-page/ChooseBankMethodPageConnection';
import CreatePlaidVerifiedBankPageConnection from '../../components/organisms/phone-pages/2-attach-bank/create-plaid-verified-bank-page/CreatePlaidVerifiedBankPageConnection';
import VerifiedBankAttachedPayBillPageConnection from '../../components/organisms/phone-pages/2-attach-bank/verified-bank-attached-page/VerifiedBankAttachedPayBillPageConnection';
import VerifiedBankAttachedSendToFriendPageConnection from '../../components/organisms/phone-pages/2-attach-bank/verified-bank-attached-page/VerifiedBankAttachedSendToFriendPageConnection';
import BankAttachedMicroDepositsPageConnection from '../../components/organisms/phone-pages/2-attach-bank/bank-attached-micro-deposits-page/BankAttachedMicroDepositsPageConnection';
import MicroDepositsPageConnection from '../../components/organisms/phone-pages/2-attach-bank/micro-deposits-page/MicroDepositsPageConnection';
import BankVerifiedPayBillPageConnection from '../../components/organisms/phone-pages/2-attach-bank/bank-verified-page/BankVerifiedPayBillPageConnection';
import BankVerifiedSendToFriendPageConnection from '../../components/organisms/phone-pages/2-attach-bank/bank-verified-page/BankVerifiedSendToFriendPageConnection';
import AddAnotherBankPageConnection from '../../components/organisms/phone-pages/2-attach-bank/funding-sources-page/AddAnotherBankPageConnection';
import TransferBetweenFundingSourcesPageConnection from '../../components/organisms/phone-pages/2-attach-bank/funding-sources-page/TransferBetweenFundingSourcesPageConnection';

import SendPayoutPageConnection from '../../components/organisms/phone-pages/3-move-money/send-money-page/SendPayoutPageConnection';
import PayBillPageConnection from '../../components/organisms/phone-pages/3-move-money/send-money-page/PayBillPageConnection';
import SendToFriendPageConnection from '../../components/organisms/phone-pages/3-move-money/send-money-page/SendToFriendPageConnection';
import TransferCompletePageConnection from '../../components/organisms/phone-pages/3-move-money/transfer-complete-page/TransferCompletePageConnection';
import SendToFriendCompletePageConnection from '../../components/organisms/phone-pages/3-move-money/transfer-complete-page/SendToFriendCompletePageConnection';
import TransferBetweenAccountsPageConnection from '../../components/organisms/phone-pages/3-move-money/send-money-page/TransferBetweenAccountsPageConnection';

const send = {
  name: 'send',
  enabled: true,
  createsVerifiedCustomer: false,
  createSteps: [
    CreateUnverifiedCustomerPageConnection,
    CustomerCreatedPageConnection,
  ],
  bankSteps: {
    default: [
      CreateUnverifiedBankPageConnection,
      UnverifiedBankAttachedPayoutsPageConnection,
    ],
  },
  sendSteps: [
    SendPayoutPageConnection,
    () => <TransferCompletePageConnection text="You Received a Payout" />,
  ],
};

const receive = {
  name: 'receive',
  enabled: true,
  createsVerifiedCustomer: false,
  createSteps: [
    CreateUnverifiedCustomerPageConnection,
    CustomerCreatedPageConnection,
  ],
  bankSteps: {
    default: [ChooseBankMethodPageConnection],
    plaid: [
      ChooseBankMethodPageConnection,
      CreatePlaidVerifiedBankPageConnection,
      VerifiedBankAttachedPayBillPageConnection,
    ],
    microDeposits: [
      ChooseBankMethodPageConnection,
      () => <CreateUnverifiedBankPageConnection stepPercentage={25} />,
      BankAttachedMicroDepositsPageConnection,
      MicroDepositsPageConnection,
      BankVerifiedPayBillPageConnection,
    ],
  },
  sendSteps: [
    PayBillPageConnection,
    () => <TransferCompletePageConnection text="Bill Payment Successful" />,
  ],
};

const sendReceive = {
  ...send,
  name: 'sendReceive',
};

const facilitate = {
  name: 'facilitate',
  enabled: true,
  createsVerifiedCustomer: true,
  createSteps: [
    CreateVerifiedCustomerSendToFriendPageConnection,
    CustomerCreatedPageConnection,
  ],
  bankSteps: {
    default: [ChooseBankMethodPageConnection],
    plaid: [
      ChooseBankMethodPageConnection,
      CreatePlaidVerifiedBankPageConnection,
      VerifiedBankAttachedSendToFriendPageConnection,
    ],
    microDeposits: [
      ChooseBankMethodPageConnection,
      () => <CreateUnverifiedBankPageConnection stepPercentage={25} />,
      BankAttachedMicroDepositsPageConnection,
      MicroDepositsPageConnection,
      BankVerifiedSendToFriendPageConnection,
    ],
  },
  sendSteps: [SendToFriendPageConnection, SendToFriendCompletePageConnection],
};

const meToMe = {
  name: 'meToMe',
  enabled: true,
  createsVerifiedCustomer: true,
  createSteps: [
    CreateVerifiedCustomerPageConnection,
    () => (
      <CustomerCreatedPageConnection message="Now let's get your bank accounts hooked up." />
    ),
  ],
  bankSteps: {
    default: [ChooseBankMethodPageConnection],
    plaid: [
      ChooseBankMethodPageConnection,
      () => <CreatePlaidVerifiedBankPageConnection stepPercentage={25} />,
      () => (
        <AddAnotherBankPageConnection
          stepPercentage={50}
          successNotificationText="Verified bank added!"
        />
      ),
      () => <CreateUnverifiedBankPageConnection stepPercentage={75} />,
      () => (
        <TransferBetweenFundingSourcesPageConnection
          stepPercentage={100}
          successNotificationText="Bank added!"
        />
      ),
    ],
    microDeposits: [
      ChooseBankMethodPageConnection,
      () => <CreateUnverifiedBankPageConnection stepPercentage={16.7} />,
      () => <BankAttachedMicroDepositsPageConnection stepPercentage={33.3} />,
      () => <MicroDepositsPageConnection stepPercentage={50} />,
      () => (
        <AddAnotherBankPageConnection
          stepPercentage={66.7}
          successNotificationText="Bank verified!"
        />
      ),
      () => <CreateUnverifiedBankPageConnection stepPercentage={83.3} />,
      () => (
        <TransferBetweenFundingSourcesPageConnection
          stepPercentage={100}
          successNotificationText="Bank added!"
        />
      ),
    ],
  },
  sendSteps: [
    TransferBetweenAccountsPageConnection,
    () => <TransferCompletePageConnection text="Transfer Successful" />,
  ],
};

export default {
  send,
  receive,
  sendReceive,
  facilitate,
  meToMe,
};
