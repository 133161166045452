import logoCurrencycloud from '../assets/images/partners/logo-currencycloud.svg';
import logoPlaid from '../assets/images/partners/logo-plaid.svg';
import logoSift from '../assets/images/partners/logo-sift.svg';
import logoTransfermate from '../assets/images/partners/logo-transfermate.svg';
import logoWeWriteCode from '../assets/images/partners/logo-we-write-code.svg';
import logoMiddesk from '../assets/images/partners/logo-middesk.svg';
import logoOnfido from '../assets/images/partners/logo-onfido.svg';

const partnerInfo = [
  {
    logo: logoWeWriteCode,
    badgeText: 'Featured',
    name: 'We Write Code',
    industry: 'Development Firm',
    description:
      'No matter what type of project you have in mind, We Write Code can make your ideas happen.',
    link: 'https://www.dwolla.com/partnerships/we-write-code/',
  },
  {
    logo: logoCurrencycloud,
    name: 'Currencycloud',
    industry: 'International Payments',
    description: 'International payments capabilities without the complexity.',
    link: 'https://www.dwolla.com/partnerships/currencycloud/',
  },
  {
    logo: logoSift,
    name: 'Sift',
    industry: 'Fraud Prevention',
    description:
      'The Digital Trust & Safety Suite, powered by Sift, makes it easier to prevent fraud, streamline operations and drive revenue growth.',
    link: 'https://www.dwolla.com/integrations/sift/',
  },
  {
    logo: logoPlaid,
    name: 'Plaid',
    industry: 'Bank Verification',
    description:
      'Instant bank account verification for ACH payments, starting with a few lines of code.',
    link: 'https://www.dwolla.com/integrations/plaid/',
  },
  {
    logo: logoTransfermate,
    name: 'TransferMate',
    industry: 'International Payments',
    description: 'Send and receive payments internationally.',
    link: 'https://www.dwolla.com/partnerships/transfermate/',
  },
  {
    logo: logoMiddesk,
    name: 'Middesk',
    industry: 'Business Verification',
    description:
      'Gain trust with minimal friction by gathering due diligence information about new business accounts on your platform.',
    link: 'https://www.dwolla.com/partnerships/middesk/',
  },
  {
    logo: logoOnfido,
    name: 'Onfido',
    industry: 'Identity Verification',
    description:
      'Identity verification at the speed of life. As simple as an ID and a selfie.',
    link: 'https://www.dwolla.com/partnerships/onfido/',
  },
];

export default partnerInfo;
