/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { themeOptions } from '../../../../../utils/propTypeHelper';
import PhonePage from '../../../phone-page/PhonePage';
import useFocusOnPageLoad from '../../../../../hooks/focus-on-page-load';
import { areValidMicroDepositAmounts } from '../../../../../utils/validators';
import { slowFill, chance } from '../../../../../utils/autofill';
import PageNavWithAutoFill from '../../../../molecules/page-nav/PageNavWithAutofill';
import VerifyMicroDepositsRequestCode from '../../../../molecules/code/VerifyMicroDepositsRequestCode';
import MicroDepositsScreen from '../../../phone-screens/2-attach-bank/MicroDepositsScreen';

const MicroDepositsPage = ({
  theme,
  stepPercentage,
  bearerToken,
  pending,
  fundingSourceUrl,
  submitVerifyBank,
  handleBackClicked,
}) => {
  const [amount1, setAmount1] = useState(0);
  const [amount2, setAmount2] = useState(0);

  const elToFocus = useRef(null);
  useFocusOnPageLoad(elToFocus);

  const isValidInput =
    !pending && areValidMicroDepositAmounts(amount1, amount2);

  const handleSubmitClicked = e => {
    e.preventDefault();
    submitVerifyBank({ fundingSourceUrl, amount1, amount2 });
  };

  const generateMicroDepositAmt = () =>
    (
      chance.integer({
        min: 1,
        max: 8,
      }) / 100
    ).toString();

  const handleAutoFill = () => {
    setAmount1('');
    setAmount2('');

    slowFill(generateMicroDepositAmt(), setAmount1).then(() =>
      slowFill(generateMicroDepositAmt(), setAmount2)
    );
  };

  return (
    <PhonePage
      theme={theme}
      stepPercentage={stepPercentage}
      currentStep={2}
      processing={pending}
      requestCodeComponent={
        <VerifyMicroDepositsRequestCode
          {...{
            fundingSourceUrl,
            amount1,
            amount2,
            bearerToken,
          }}
        />
      }
      pageNavComponent={
        <PageNavWithAutoFill
          {...{
            prevOnClick: handleBackClicked,
            isValidInput,
            pending,
            handleAutoFill,
            handleSubmitClicked,
          }}
        />
      }
    >
      <MicroDepositsScreen
        {...{
          theme,
          isValidInput,
          pending,
          elToFocus,
          amount1,
          setAmount1,
          amount2,
          setAmount2,
          handleSubmitClicked,
        }}
      />
    </PhonePage>
  );
};

MicroDepositsPage.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  stepPercentage: PropTypes.number,
  bearerToken: PropTypes.string.isRequired,
  pending: PropTypes.bool.isRequired,
  fundingSourceUrl: PropTypes.string.isRequired,
  submitVerifyBank: PropTypes.func.isRequired,
  handleBackClicked: PropTypes.func.isRequired,
};

MicroDepositsPage.defaultProps = {
  stepPercentage: 75,
};

export default MicroDepositsPage;
